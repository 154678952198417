<template>
  <nav class="pager" aria-label="Pagesize">
    <ul class="pagination pagination-sm justify-content-end">
        <li
            v-for="(option, index) in options"
            :key="index"
            :class="{ active: (value == option.id), 'page-item': true }"
        >
          <span :aria-current="(value == option.id ? 'page' : '')" class="page-link" @click="setPageSize(option.id)">{{ option.value }}</span>
        </li>
    </ul>
  </nav>
<!--  <div class="input-group input-group-sm">
    <select class="form-control" v-model="value" @change="setPageSize">
      <option v-bind:value="option.id"
              :selected="{ selected: option.id == value }"
              v-for="(option, index) in options"
              :key="index"
      >{{ option.value }}</option>
    </select>
  </div>-->
</template>
<script>
export default {
  name: "PageSize",
  props: {
    dataService: null,
  },
  methods: {
    setPageSize(s) {
      this.value = s;
      this.$emit('setPageSize', this.value);
    }
  },
  data() {
    return {
      value: 20,
      options: [
        {id: 20, value: 20},
        {id: 50, value: 50},
        {id: 100, value: 100},
        {id: 250, value: 250},
        {id: 500, value: 500},
        {id: 1000, value: 1000},
        {id: "all", value: "all"},
      ]
    }
  }
}
</script>
