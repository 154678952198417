<template>
  <div class="input-group input-group-sm">
    <select class="form-control" v-bind:id="fieldname" v-model="value" @change="search">
      <option v-bind:value="option.value"
              :selected="{ selected: option.value == value }"
              v-for="(option, index) in cfg.options"
              :key="index"
      >{{ option.label }}</option>
    </select>
    <div class="input-group-text" @click="toggleDirection()">
      <font-awesome-icon :icon="['fas', getSortIcon()]" />
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickSearchEnum",
  props: {
    fieldname: String,
    cfg: null,
    dataService: null,
    titleProperty: String
  },
  methods: {
    search: function() {
      this.$emit('search');
    },
    getValue: function() {
      return this.value;
    },
    getProperty: function() {
      return this.fieldname;
    },
    getSortIcon() {
      if (! this.direction) return "sort";
      if (this.direction == "asc") return "sort-down";
      return 'sort-up';
    },
    toggleDirection() {
      this.direction = this.direction == "asc" ? "desc" : (this.direction == "desc" ? null : "asc");
      this.$emit('sort', this.fieldname, this.direction);
    },
  },
  data() {
    return {
      options: [],
      value: 0,
      direction: null
    }
  },
  created: function() {
    this.dataService.registerSearchComponent(this);
  },
  beforeUnmount: function() {
    this.dataService.unregisterSearchComponent(this);
  }
}
</script>
