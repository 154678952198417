<template>
  <div class="action-bar justify-content-start">
    <template v-for="(option, index) in gridActions" :key="index">
        <router-link :to="option.route" class="input-group input-group-sm">
        <div class="input-group-text btn-success">
          <font-awesome-icon :icon="['fas', option.icon ]"/>
        </div>
        <div class="form-control">
            <span v-if="option.label">{{ option.label }}</span>
        </div>
        </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "ActionBar",
  props: {
    gridActions: {
      type: Array,
      required: true,
    }
  },
}
</script>
