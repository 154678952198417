<template>
  <nav class="pager" aria-label="Pagination" v-if="paginationInfo">
    <ul class="pagination pagination-sm justify-content-center">
      <li class="page-item" :class="{disabled: !getFirstPage()}">
        <span class="page-link" v-bind:data-page-number="1" @click="search" href="#">
          <font-awesome-icon :icon="['fas', 'angle-double-left']" />
        </span>
      </li>
      <li class="page-item" :class="{disabled: !getPreviousPage()}">
        <span @click="search" v-bind:data-page-number="getPreviousPage()" class="page-link" href="#" tabindex="-1" :aria-disabled="(getPreviousPage() ? '' : 'true')">
          <font-awesome-icon :icon="['fas', 'angle-left']" />
        </span>
      </li>

      <li v-if="showPreviousDots()" class="page-item disabled">
        <span class="page-link" href="#" aria-disabled="true">...</span>
      </li>

      <template v-for='index in paginationInfo.last_page'>
        <li :key='index'
            v-if="showPaginationItem(index)"
            :class="{ active: (index == paginationInfo.current_page), 'page-item': true }"
        >
          <span :aria-current="(index == paginationInfo.current_page ? 'page' : '')" class="page-link" v-bind:data-page-number="index" @click="search" href="#">{{ index }}</span>
        </li>
      </template>

      <li v-if="showNextDots()" class="page-item disabled">
        <span class="page-link" href="#" aria-disabled="true">...</span>
      </li>

      <li class="page-item" :class="{disabled: !getNextPage()}">
        <span class="page-link" @click="search" v-bind:data-page-number="getNextPage()" href="#">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </span>
      </li>
      <li class="page-item" :class="{disabled: !getLastPage()}">
        <span class="page-link" v-bind:data-page-number="getLastPage()" @click="search" href="#">
          <font-awesome-icon :icon="['fas', 'angle-double-right']" />
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    id: String,
    dataService: null,
    paginationInfo: null
  },
  methods: {
    search: function(e) {
      let target = e.target;
      let i = 0;
      while(i < 5 && (! target.dataset.pageNumber)) {
        i++;
        target = target.parentNode;
      }

      target.blur();

      let pageNumber = target.dataset.pageNumber;

      e.preventDefault();
      this.value = pageNumber;
      this.$emit('paginate', pageNumber);
    },
    getValue: function() {
      return this.value;
    },
    getId: function() {
      return this.id;
    },
    showPaginationItem(index) {
      let cp = this.paginationInfo.current_page;
      if (cp < (index + 3) && cp > (index - 3)) return true;
      return false;
    },
    showNextDots() {
      if ((this.paginationInfo.current_page + 2) < this.paginationInfo.last_page) return true;
      return false;
    },
    showPreviousDots() {
      if ((this.paginationInfo.current_page - 3) > 0) return true;
      return false;
    },
    getNextPage() {
      let v = this.paginationInfo.current_page + 1;
      if (v > this.paginationInfo.last_page) return null;
      return v;
    },
    getPreviousPage() {
      let v = this.paginationInfo.current_page - 1;
      if (v < 1) return null;
      return v;
    },
    getFirstPage() {
      if (this.paginationInfo.current_page == 1) return null;
      return 1;
    },
    getLastPage() {
      if (this.paginationInfo.last_page == this.paginationInfo.current_page) return null;
      return this.paginationInfo.last_page;
    }
  },
  data() {
    return {
      value: 1
    }
  },
  created: function() {
    this.dataService.registerPaginator(this);
  },
  beforeUnmount: function() {
    this.dataService.unregisterPaginator(this);
  }
}
</script>
