<template>
  <div class="input-group input-group-sm">
    <select class="form-control" v-bind:id="fieldname" v-model="value" @change="search">
      <option value="0">No</option>
      <option value="1">Yes</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "QuickSearchBool",
  props: {
    fieldname: String,
    dataService: null,
    titleProperty: String
  },
  methods: {
    search: function() {
      this.$emit('search');
    },
    getValue: function() {
      return this.value;
    },
    getProperty: function() {
      return this.fieldname;
    },
    resetSort() {
      this.direction = null;
    }
  },
  data() {
    return {
      options: [],
      value: 0,
      direction: null
    }
  },
  created: function() {
    this.dataService.registerSearchComponent(this);
  },
  beforeUnmount: function() {
    this.dataService.unregisterSearchComponent(this);
  }
}
</script>
