<template>
  <div class="input-group input-group-sm">
    <select class="form-control" v-bind:id="fieldname" v-model="value" @change="search">
      <option v-bind:value="option.id"
              :selected="{ selected: option.id == value }"
              v-for="(option, index) in options"
              :key="index"
      >{{ option[titleProperty] }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "QuickSearchSelect",
  props: {
    fieldname: String,
    optionsDataService: null,
    dataService: null,
    titleProperty: String
  },
  methods: {
    search: function() {
      this.$emit('search');
    },
    getValue: function() {
      return this.value;
    },
    getProperty: function() {
      return this.fieldname;
    },
    resetSort() {
      this.direction = null;
    }
  },
  data() {
    return {
      options: [],
      value: 0,
      direction: null
    }
  },
  created: function() {
    this.dataService.registerSearchComponent(this);
    this.optionsDataService.getAll().then(response => {
        this.options = response.data.data;
        let el = {id: 0 };
            el[this.titleProperty] = "";

        this.options.unshift(el);
      }).catch(e => {
        console.log(e);
      });
  },
  beforeUnmount: function() {
    this.dataService.unregisterSearchComponent(this);
  }
}
</script>
