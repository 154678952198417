<template>
  <div v-if="batchActions && batchActions.length" class="batch-action-bar justify-content-start">
    <div class="row">
      <div class="col">
        <div class="input-group input-group-sm">
          <div class="input-group-text">
            <font-awesome-icon :icon="['fas', 'database']" />
          </div>
          <select class="form-select" v-model="action" @change="onChange">
            <option v-if="! fixedAction" :value="null" disabled>Select Batch Action</option>
            <option v-for="(obj, prop) in batchActions"
              :key="prop"
              :value="obj"
              :selected="action && action.id == obj.id"
            >
              {{ obj.name }}
            </option>
          </select>
          <button title="apply" class="btn btn-success btn-sm" v-if="this.action" @click="onSubmit">
            <font-awesome-icon :icon="['fas', 'check']" />    apply
          </button>
          <button v-if="! fixedAction && this.action" title="cancel" class="btn btn-danger btn-sm" @click="onClear">
            <font-awesome-icon :icon="['fas', 'times']" />    cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BatchActionBar",
  props: {
    batchActions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onChange() {
      this.$emit("activateBatchAction", this.action);
    },
    onSubmit() {
      this.$emit("submitBatchAction", this.action);
      this.onClear();
    },
    onClear() {
      this.$emit("clearBatchAction", this.action, (this.fixedAction ? true : false));
      if (! this.fixedAction) {
        this.action = null;
      }
    }
  },
  setup(props, context) {
    let fixedAction = null;
    props.batchActions.forEach(action => {
      if (action.fixed !== undefined && action.fixed) fixedAction = action;
    });

    if (fixedAction) {
      context.emit("activateBatchAction", fixedAction);
    }
    return {
      action: fixedAction,
      fixedAction: fixedAction
    }
  }
}
</script>
