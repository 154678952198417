<template>
  <div class="input-group input-group-sm">
    <input v-bind:type="type" v-model="sword" v-bind:fieldname="fieldname" @keyup.enter="search" class="form-control" />
    <div class="input-group-text" @click="toggleDirection()">
      <font-awesome-icon :icon="['fas', getSortIcon()]" />
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickSearchInput",
  props: {
    fieldname: String,
    dataService: null,
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {
      sword: "",
      direction: ""
    }
  },
  methods: {
    search() {
      this.$emit('search');
    },
    getValue() {
      return this.sword ? this.sword : '';
    },
    getProperty() {
      return this.fieldname;
    },
    getSortIcon() {
      if (! this.direction) return "sort";
      if (this.direction == "asc") return "sort-down";
      return 'sort-up';
    },
    toggleDirection() {
      this.direction = this.direction == "asc" ? "desc" : (this.direction == "desc" ? null : "asc");
      this.$emit('sort', this.fieldname, this.direction);
    },
    resetSort() {
      this.direction = null;
    }
  },
  created: function() {
    this.dataService.registerSearchComponent(this);
  },
  beforeUnmount: function() {
    this.dataService.unregisterSearchComponent(this);
  }
}
</script>
